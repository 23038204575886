<template>
  <notification-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ $t('_.title') }}</span>
    </template>
    <p class="main-msg">{{ $t('_.statusMessage', { title, teamName }) }}</p>
  </notification-item>
</template>

<script>
import NotificationItem from '@shared/components/notification/NotificationItem.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'SelectedMemberItem',
  lexicon: 'shared.notification.APPLICATION_SELECTED_MEMBER',
  components: { NotificationItem },
  props: {
    message: Specific,
  },
  computed: {
    application() {
      return this.message?.data?.application || {};
    },
    team() {
      return this.application.team || {};
    },
    tournament() {
      return this.application.tournament || {};
    },
    title() {
      return this.tournament.title || this.$t('anonymous.arena');
    },
    teamName() {
      return this.team.name || this.$t('anonymous.team');
    },
  },
  methods: {
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
</style>
